<template>
  <!-- <div class="__box"> -->
    <!-- <Main /> -->
    <router-view></router-view>
  <!-- </div> -->
</template>

<script>
// import Main from "./components/main.vue";
export default {
  components: {
    // Main,
  },
  mounted() {
    console.log("你好");
  },
};
</script>

<style>
@import url("./assets/style/common.css");
/* .__box {
  min-width: 1300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
} */
</style>
