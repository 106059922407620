import Vue from "vue";
import VueRouter from "vue-router";
// import Layout from '../views/layout'

Vue.use(VueRouter);
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
const routes = [
  // 首页
  {
    path: "",
    name: "home",
    component: () => import("../components/main.vue"),
  },
  {
    path: '/bd01',
    name: 'bd01',
    component: () => import('../views/bd01.vue'),
  },
  {
    path: '/360',
    name: '360',
    component: () => import('../views/360.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue'),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
